<template>
  <div class="wrap">
    <Aside v-if="asideStatus" @closeAside="asideStatus = false" />
    <!-- 스페이스 삭제 -->
    <CommonConfirm
      v-if="ShowDelete"
      :confirmid="'deletespace'"
      @close-modal="onCloseDeleteModal"
      @confirm-modal="onDeleteSpace"
    >
      <template #contents>
        <h3 v-if="spaceNm" class="deletespace_modal_title" v-html="`${spaceNm}의 스페이스를 삭제하시겠습니까?`"></h3>
        <h3 v-else class="deletespace_modal_title">
          스페이스를 삭제하시겠습니까?
        </h3>
        <p class="deletespace_modal_contents">
          스페이스를 삭제하면 해당 공간 정보가 삭제됩니다. <br />
          자동 큐레이터에 누적된 취향 음악이 삭제됩니다.
        </p>
      </template>
    </CommonConfirm>
    <PopUpModal v-if="isPopUpModal" :text="popupModalText" @close-modal="onClosePopUpModal" />
    <!-- 스페이스 재구독 -->
    <CommonConfirm
      v-if="ShowResubscribe"
      :confirmid="'resubscribe'"
      @close-modal="ShowResubscribe = false"
      @confirm-modal="onReSubscribe"
    >
      <template #contents>
        <h3 class="common_contents_text">
          재구독 하시겠습니까?
        </h3>
      </template>
    </CommonConfirm>
    <!-- 재생 하시겠습니까 팝업 -->
    <CommonConfirm v-if="ShowPlay" :confirmid="'play'" @close-modal="ShowPlay = false" @confirm-modal="onPlay">
      <template #contents>
        <h3 class="common_contents_text">
          해당 스페이스로 재생하시겠습니까?
        </h3>
      </template>
    </CommonConfirm>
    <!-- 이어서 등록하시겠습니까 팝업 -->
    <CommonConfirm
      v-if="ShowAddSpace"
      :confirmid="'addspace'"
      @close-modal="ShowAddSpace = false"
      @confirm-modal="onNextInsert"
    >
      <template #contents>
        <h3 class="common_contents_text">
          이어서 등록하시겠습니까?
        </h3>
      </template>
    </CommonConfirm>
    <!-- 브랜드 연결 해제 팝업-->
    <CommonConfirm
      v-if="isDisconnect"
      :confirmid="'disconnect'"
      @close-modal="isDisconnect = false"
      @confirm-modal="onDisConnect"
    >
      <template #contents>
        <h3 class="common_contents_text" v-html="brandNm + ' 와(과)의 연결을 해제할까요?'"></h3>
      </template>
    </CommonConfirm>

    <div class="space_pc" style="overflow: auto" @click="hideSetBox2">
      <section class="main myspace myspace0">
        <div class="main_box">
          <div class="main_box_title">
            <h1 style="margin-bottom: 0px">마이 스페이스</h1>
            <p style="margin-bottom: 0px" @click="linkInsertSpace()" v-if="spaceList.length > 0">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.3 11.45H11.45V11.3V8.65H12.55V11.3V11.45H12.7H15.35V12.55H12.7H12.55V12.7V15.35H11.45V12.7V12.55H11.3H8.65V11.45H11.3ZM12 18.85C8.21674 18.85 5.15 15.7833 5.15 12C5.15 8.21674 8.21674 5.15 12 5.15C15.7833 5.15 18.85 8.21674 18.85 12C18.85 15.7833 15.7833 18.85 12 18.85ZM12 17.75C13.525 17.75 14.9875 17.1442 16.0659 16.0659C17.1442 14.9875 17.75 13.525 17.75 12C17.75 10.475 17.1442 9.01247 16.0659 7.93414C14.9875 6.8558 13.525 6.25 12 6.25C10.475 6.25 9.01247 6.8558 7.93414 7.93414C6.8558 9.01247 6.25 10.475 6.25 12C6.25 13.525 6.8558 14.9875 7.93414 16.0659C9.01247 17.1442 10.475 17.75 12 17.75Z"
                  fill="white"
                  stroke="#151515"
                  stroke-width="0.3"
                />
              </svg>
              신규 스페이스 추가
            </p>
          </div>
          <skelton-myspace v-if="isFirstLoading" />
          <div class="myspace_content" v-else-if="spaceList.length === 0 && !isFirstLoading">
            <div class="empty_card" @click="linkInsertSpace()">
              <img src="/media/img/card_upload.png" alt="" />
              <p style="margin-bottom: 0px">신규 스페이스 추가</p>
            </div>
          </div>

          <div class="my_space_content" v-else-if="spaceList.length > 0 && !isFirstLoading">
            <template>
              <div
                v-for="(item, index) in spaceList"
                class="new_space"
                :key="index"
                v-bind:class="{
                  on:
                    item.spaceId === useId &&
                    item.spaceId === useId2 &&
                    (item.spacePayAt === 'Y' || item.spacePayAt === 'T') &&
                    isUseSpace
                }"
              >
                <span
                  v-if="
                    item.spaceId === useId &&
                      item.spaceId === useId2 &&
                      (item.spacePayAt === 'Y' || item.spacePayAt === 'T') &&
                      isUseSpace
                  "
                  ><img src="/media/img/using_icon.svg" alt="" />이용중</span
                >
                <div class="setting_area" @click="showSetBox($event)">
                  <div id="setting_icon">
                    <img src="/media/img/setting_icon.svg" class="show_select_box" />
                  </div>
                  <div class="setting_show_box" v-if="item.spacePayAt === 'Y' || item.spacePayAt === 'T'">
                    <p @click="linkUpdateSpace(item)">
                      <img src="/media/img/toggle_setting.svg" alt="" style="width: 16px" />스페이스 설정
                    </p>
                    <p v-if="item.settingSpaceYn === 'N'" @click="onClickCurationitem(item)">
                      <img src="/media/img/new_brand_setting_icon2.svg" alt="" />큐레이션 설정
                    </p>
                    <p
                      @click="clickDisconnect(item)"
                      v-if="item.acceptAt === 'Y' && (item.spacePayAt === 'Y' || item.spacePayAt === 'T')"
                    >
                      <img src="/media/img/new_brand_setting_icon3.svg" alt="" />브랜드 연결 해제
                    </p>
                    <p @click="linkUpdateFranchise(item)" v-else>
                      <img src="/media/img/new_brand_setting_icon3.svg" alt="" />브랜드 연결
                    </p>
                    <p @click="linkAutoPlay(item)" v-if="item.spaceType !== 'Car' && item.spacePayAt !== 'T'">
                      <img src="/media/img/new_brand_setting_icon4.svg" alt="" />자동 재생 설정
                    </p>
                    <p @click="linkCarPurpose(item)" v-if="item.spaceType === 'Car'">
                      <img src="/media/img/new_brand_setting_icon4.svg" alt="" />이용 목적 설정
                    </p>
                  </div>
                  <div class="setting_show_box" v-else>
                    <p @click="deleteSpace(item)"><img src="/media/img/icon_delete_16px.svg" alt="" />스페이스 삭제</p>
                  </div>
                </div>
                <div class="disabled" @click="clickSpace(item)" v-if="item.brandImg != null">
                  <img :src="baseImgUrl + item.brandImg" alt="" />
                </div>
                <div class="disabled" @click="clickSpace(item)" v-else>
                  <img :src="item.spaceImg" alt="스페이스이미지" />
                </div>
                <div class="disabled myspace_item" @click="clickSpace(item)">
                  <div class="new_space_span">
                    <span id="subscribe" v-if="item.spacePayAt === 'Y'">구독중</span>
                    <span style="color: #fff; background: rgba(255, 255, 255, 0.16)" v-else-if="item.spacePayAt === 'N'"
                      >미구독</span
                    >
                    <span
                      style="color: rgba(55, 183, 255, 1); background: rgba(55, 183, 255, 0.16)"
                      v-else-if="item.spacePayAt === 'T'"
                      >체험용</span
                    >
                    <span style="color: #98db78; background: rgba(152, 219, 120, 0.16)" v-else>저장중</span>
                    <span id="eorl" v-if="item.requestAt === 'Y'">승인대기</span>
                    <span id="dhksfy" v-if="item.acceptAt === 'Y' && item.spacePayAt === 'Y'">브랜드 소속</span>
                    <span
                      style="color: #eeeeee; background: rgba(238, 101, 250, 0.16)"
                      v-if="item.autoplay === 'Y' && item.spacePayAt === 'Y'"
                      >자동재생</span
                    >
                  </div>
                  <h3 v-html="item.spaceNm" />
                  <p v-if="item.expireDate != null && (item.spacePayAt === 'Y' || item.spacePayAt === 'T')">
                    {{ dateTime(item.expireDate) }}까지 이용가능합니다
                  </p>
                  <p v-else>&nbsp;</p>
                  <div class="quration_list">
                    <span v-if="item.mood != null">{{ item.mood }}</span>

                    <span v-if="item.target != null && item.target.length === 1">{{ item.target[0] }}</span>
                    <span v-else-if="item.target != null && item.target.length > 1"
                      >{{ item.target[0] }} + {{ item.target.length - 1 }}</span
                    >
                    <span v-if="item.targetUnit != null && item.targetUnit.length === 1">{{ item.targetUnit[0] }}</span>
                    <span v-else-if="item.targetUnit != null && item.targetUnit.length > 1"
                      >{{ item.targetUnit[0] }} + {{ item.targetUnit.length - 1 }}</span
                    >
                    <span v-if="item.carPurpose != null && item.carPurpose.length === 1">{{ item.carPurpose[0] }}</span>
                    <span v-else-if="item.carPurpose != null && item.carPurpose.length > 1"
                      >{{ item.carPurpose[0] }} + {{ item.carPurpose.length - 1 }}</span
                    >
                    <span v-if="item.carMood != null">{{ item.carMood }}</span>
                    <span v-if="item.domesticRate != null">국내{{ item.domesticRate }}%</span>
                    <span v-if="item.genre != null && item.genre.length === 1">{{ item.genre[0] }}</span>
                    <span v-if="item.genre != null && item.genre.length > 1"
                      >{{ item.genre[0] }} + {{ item.genre.length - 1 }}</span
                    >
                    <span v-if="item.period != null && item.period.length === 1">{{ item.period[0] }}년대</span>
                    <span v-else-if="item.period != null && item.period.length > 1"
                      >{{ item.period[0] }}년대 + {{ item.period.length - 1 }}</span
                    >
                    <span v-if="item.emotion != null && item.emotion.length > 1"
                      >{{ item.emotion[0] }} + {{ item.emotion.length - 1 }}</span
                    >
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </section>
    </div>
    <!-- 모바일 -->
    <div class="space_mo hauto">
      <header class="header mo_header" @click="hideSetBoxMo()">
        <div class="header_box">
          <div class="logo">
            <a>
              <img src="/media/images/icon_back_24.svg" @click="linkPlayer()" />
              <h2 style="margin-bottom: 0px">마이스페이스</h2>
            </a>
          </div>
          <div class="right_menu">
            <div class="alarm_box" @click="mobileAlarm">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.02759 19.7773C9.02759 20.5181 9.46002 21.9996 11.1897 21.9996C12.9195 21.9996 13.3519 20.5181 13.3519 19.7773"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2.00024 18.666H20.3786"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.62183 18.1113V10.3335C3.62183 7.92613 5.13534 3.11133 11.1894 3.11133C17.2434 3.11133 18.7569 8.2965 18.7569 10.8891V18.1113"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <ellipse
                  v-if="$store.state.noticeCount !== 0"
                  cx="17.6756"
                  cy="6.44444"
                  rx="4.32431"
                  ry="4.44444"
                  fill="#FF1150"
                />
              </svg>
            </div>
            <div class="toggle" @click="showAside">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2231_20993)">
                  <path
                    d="M24 20L6 20L6 18L24 18L24 20ZM24 13L12 13L12 11L24 11L24 13ZM24 6L6 6L6 4L24 4L24 6Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2231_20993">
                    <rect width="24" height="24" fill="white" transform="translate(24 24) rotate(180)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
      </header>
      <section class="main myspace myspace0" style="overflow: auto">
        <div class="main_box space_list">
          <div class="my_space_content no_margin_bottom">
            <div class="new_space_empty no_margin_bottom" @click="hideSetBoxMo()">
              <div @click="linkInsertSpace()">
                <img src="/media/img/new_space_upload.svg" alt="" />
              </div>
              <h3 style="margin-bottom: 0px">신규 스페이스 추가</h3>
            </div>
            <template>
              <div
                v-for="(item, index) in spaceList"
                class="new_space"
                v-bind:class="{
                  first: index === 0,
                  Mo: item.spaceId === useId && item.spaceId === useId2 && isUseSpace
                }"
                :key="index"
              >
                <div class="disabled" @click="[clickSpace(item), hideSetBoxMo()]">
                  <div v-if="item.brandImg != null">
                    <span
                      v-if="
                        item.spaceId === useId &&
                          item.spaceId === useId2 &&
                          (item.spacePayAt === 'Y' || item.spacePayAt === 'T') &&
                          isUseSpace
                      "
                      >이용중</span
                    >
                    <img :src="baseImgUrl + item.brandImg" alt="" />
                  </div>
                  <div v-else>
                    <span
                      v-if="
                        item.spaceId === useId && (item.spacePayAt === 'Y' || item.spacePayAt === 'T') && isUseSpace
                      "
                      >이용중</span
                    >
                    <img :src="item.spaceImg" alt="스페이스 이미지" />
                  </div>
                  <div>
                    <div class="new_space_span">
                      <span id="subscribe" v-if="item.spacePayAt === 'Y'">구독중</span>
                      <span
                        style="color: #fff; background: rgba(255, 255, 255, 0.16)"
                        v-else-if="item.spacePayAt === 'N'"
                        >미구독</span
                      >
                      <span
                        style="color: rgba(55, 183, 255, 1); background: rgba(55, 183, 255, 0.16)"
                        v-else-if="item.spacePayAt === 'T'"
                        >체험용</span
                      >
                      <span style="color: #98db78; background: rgba(152, 219, 120, 0.16)" v-else>저장중</span>
                      <span id="eorl" v-if="item.requestAt === 'Y'">승인대기</span>
                      <span id="dhksfy" v-if="item.acceptAt === 'Y' && item.spacePayAt === 'Y'">브랜드 소속</span>
                      <span
                        style="color: #eeeeee; background: rgba(238, 101, 250, 0.16)"
                        v-if="item.autoplay === 'Y' && item.spacePayAt === 'Y'"
                        >자동재생</span
                      >
                    </div>
                    <h3 v-html="item.spaceNm" />
                    <p v-if="item.expireDate != null && (item.spacePayAt === 'Y' || item.spacePayAt === 'T')">
                      {{ dateTime(item.expireDate) }} 까지
                    </p>
                  </div>
                </div>
                <div class="setting_area" @click="showSetBoxMo($event)">
                  <div id="setting_icon">
                    <img src="/media/img/setting_icon.svg" class="show_select_box" />
                  </div>
                </div>
                <div
                  class="setting_show_box1"
                  style="padding-bottom: 16px"
                  v-if="item.spacePayAt === 'Y' || item.spacePayAt === 'T'"
                >
                  <span></span>
                  <div class="setting_title">
                    <h3 style="margin-bottom: 0px" v-html="item.spaceNm" />
                    <img src="/media/images/icon_close_24.svg" @click="hideSetBoxMo()" alt="" />
                  </div>
                  <p @click="linkUpdateSpace(item)">
                    <img src="/media/img/toggle_setting.svg" alt="" style="width: 18px" />스페이스 설정
                  </p>
                  <p @click="onClickCurationitem(item)" v-if="item.settingSpaceYn === 'N'">
                    <img src="/media/img/new_brand_setting_icon2.svg" alt="" />큐레이션 설정
                  </p>
                  <p
                    @click="clickDisconnect(item)"
                    v-if="item.acceptAt === 'Y' && (item.spacePayAt === 'Y' || item.spacePayAt === 'T')"
                  >
                    <img src="/media/img/new_brand_setting_icon3.svg" alt="" />브랜드 연결 해제
                  </p>
                  <p @click="linkUpdateFranchise(item)" v-else>
                    <img src="/media/img/new_brand_setting_icon3.svg" alt="" />브랜드 연결
                  </p>
                  <p @click="linkAutoPlay(item)" v-if="item.spaceType !== 'Car' && item.spacePayAt !== 'T'">
                    <img src="/media/img/new_brand_setting_icon4.svg" alt="" />자동 재생 설정
                  </p>
                  <p @click="linkCarPurpose(item)" v-if="item.spaceType === 'Car'">
                    <img src="/media/img/new_brand_setting_icon4.svg" alt="" />이용 목적 설정
                  </p>
                </div>
                <div class="setting_show_box1" style="padding-bottom: 12px" v-else>
                  <span></span>
                  <div class="setting_title">
                    <h3 style="margin-bottom: 0px" v-if="item.spaceNm != null" v-html="item.spaceNm" />
                    <h3 style="margin-bottom: 0px" v-else>&nbsp;</h3>
                    <img src="/media/images/icon_close_24.svg" @click="hideSetBoxMo()" alt="" />
                  </div>
                  <p @click="deleteSpace(item)"><img src="/media/img/icon_delete_16px.svg" alt="" />스페이스 삭제</p>
                </div>
              </div>
            </template>
          </div>
        </div>
      </section>
    </div>
    <infinite-loading v-if="!isFirstLoading" @infinite="infiniteHandler" spinner="waveDots" ref="infiniteLoading">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
      <div slot="error"></div>
    </infinite-loading>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import CommonConfirm from '@/components/common/CommonConfirm.vue';
import PopUpModal from '@/components/modal/common/PopUpModal.vue';
import InfiniteLoading from 'vue-infinite-loading';
import $ from 'jquery';
import dayjs from 'dayjs';
import axios from '@/service/axios';
import router from '@/router';
import Aside from '@/layout/aside/Aside.vue';
import SkeltonMySpace from '@/components/myspace/SkeltonMySpace.vue';
import { getMySpaceList, selectCurationInfoAll } from '@/service/api/profileApi';

export default defineComponent({
  props: {
    deletespace: {
      type: Function,
      required: false,
      default: null
    },
    resubscribespace: {
      type: Function,
      required: false,
      default: null
    },
    spaceplay: {
      type: Function,
      required: false,
      default: null
    },
    nextinsert: {
      type: Function,
      required: false,
      default: null
    },
    disconnect: {
      type: Function,
      required: false,
      default: null
    }
  },
  data() {
    return {
      useIOS: false,
      ShowDelete: false,
      isPopUpModal: false,
      popupModalText: null,
      ShowDelete2: false,
      ShowAddSpace: false,
      ShowResubscribe: false,
      ShowPlay: false,
      isDisconnect: false,
      today: dayjs().format('YY.MM.DD'),
      storeImg: '/media/img/Group 485982.png',
      carImg: '/media/img/car.png',
      storeImgMo: '/media/img/Thumnail_Shop.png',
      carImgMo: '/media/img/Thumnail_Car.png',
      baseImgUrl: 'https://www.aplayz.co.kr/media/upload',
      spaceList: [],
      spaceNm: '',
      spaceId: '',
      spaceType: '',
      brandId: '',
      brandNm: '',
      asideStatus: false,
      useId: '',
      useId2: this.$store.state.spaceId,
      limit: 10,
      mode: '',
      isSpaceConnection: false,
      isRegistCard: true,
      route: '',
      isFirstLoading: false,
      curationTimeList: []
    };
  },
  components: {
    Aside,
    InfiniteLoading,
    'skelton-myspace': SkeltonMySpace,
    CommonConfirm,
    PopUpModal
  },

  created() {
    this.isFirstLoading = true;

    this.checkIOS();
    this.set();
    this.checkEvent();
    this.modeCheck();
    this.setMySpaceList();
  },
  mounted() {},
  methods: {
    setMySpaceList() {
      const userId = this.$cookies.get('userId');
      const spaceId = this.$cookies.get('spaceId') ? this.$cookies.get('spaceId') : localStorage.getItem('spaceId');
      this.useId = this.$cookies.get('spaceId');
      this.useId2 = this.useId;

      getMySpaceList(userId, this.limit, spaceId)
        .then(res => {
          const list = res.data.result.spaceInfoListInfo || [];

          const len = list.length;
          for (let index = 0; index < len; index++) {
            if (list[index].genre) {
              list[index].genre = [...list[index].genre];
            } else {
              list[index].genre = [];
            }

            if (list[index].genreTuneN) {
              list[index].genre = [...list[index].genre, ...list[index].genreTuneN];
            }

            if (list.genreTuneY) {
              list[index].genre = [...list[index].genre, ...list[index].genreTuneY];
            }
          }

          const spaceId = this.$cookies.get('spaceId');
          if (spaceId) {
            const findIndex = list.findIndex(item => item.spaceId === spaceId);
            if (findIndex < 0) {
              this.limit += 10;
              this.setMySpaceList();
            } else {
              const findItem = list[findIndex];
              const filter = list.filter(el => el.spaceId !== findItem.spaceId);

              this.spaceList = [findItem, ...filter];
              this.deleteIndi(this.spaceList);
            }
          } else {
            this.spaceList = res.data.result.spaceInfoListInfo;
            this.deleteIndi(this.spaceList);
          }
        })
        .catch(err => {
          console.log('getMySpaceList : ', err);
          this.spaceList = [];
        })
        .finally(() => {
          this.isFirstLoading = false;
        });
    },
    checkRegistCard() {
      const userId = this.$cookies.get('userId');
      const aToken = this.$cookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/product/getMyCardInfo', { userId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            result != null ? (this.isRegistCard = true) : (this.isRegistCard = false);
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    linkPlayer() {
      var spaceId = this.$cookies.get('spaceId');
      if (spaceId != null) {
        router.push({ name: 'Player' });
      } else {
        router.go(-1);
      }
    },
    modeCheck() {
      var url = window.location.host;
      url.indexOf('localhost') !== -1 || url.indexOf('dev') !== -1 ? (this.mode = 'dev') : (this.mode = 'prod');
    },
    checkEvent() {
      // 이벤트 날짜 체크
      var userId = this.$cookies.get('userId');
      var aToken = this.$cookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/menu/getRecentEvent', { userId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            var result = res.data.result;
            var eventStartDate = result.eventStartDate;
            var eventEndDate = result.eventEndDate;
            // 현재날짜, 이벤트 시작/종료 날짜
            var today = dayjs();
            var startDate = dayjs(eventStartDate);
            var endDate = dayjs(eventEndDate);
            // 현재날짜와 이벤트 시작/종료 날짜의 차
            var startDay = startDate.diff(today, 'day', true);
            var endDay = endDate.diff(today, 'day', true);
            // 이벤트 시작 후 7일 경과 또는 이벤트가 종료되었을 경우
            Math.floor(startDay) < -7 || Math.floor(endDay) < 0
              ? this.$store.commit('setEventInfo', false)
              : this.$store.commit('setEventInfo', true);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    clickDisconnect(brand) {
      this.brandId = brand.brandId;
      this.brandNm = brand.brandNm;
      this.spaceId = brand.spaceId;
      this.isDisconnect = true;
    },
    getList() {
      this.limit = 10;
      this.spaceList = [];

      this.isFirstLoading = true;
      this.setMySpaceList();
    },
    async getCurationInfo() {
      const spaceId = this.$cookies.get('spaceId');
      const aToken = this.$cookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      return await axios
        .post('/api/profile/getCurationInfo', { spaceId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            return res.data.result;
          } else {
            return {};
          }
        })
        .catch(() => {
          return {};
        });
    },
    async getMySpaceInfo() {
      const spaceId = this.$cookies.get('spaceId');
      const aToken = this.$cookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      if (spaceId) {
        return await axios
          .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
          .then(res => {
            if (res.data.resultCd === '0000') {
              const result = res.data.result;
              return result;
            } else {
              return {};
            }
          })
          .catch(err => {
            console.log('err : ', err);
            return {};
          });
      }
    },
    async retryGetMySpaceList($state) {
      var userId = this.$cookies.get('userId');
      var spaceId = this.$cookies.get('spaceId');
      var aToken = this.$cookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };
      var limit = this.limit;
      return new Promise(() => {
        setTimeout(async () => {
          await axios.post('/api/profile/getMySpaceList', { userId, limit, spaceId }, { headers }).then(res => {
            const data = res.data.result.spaceInfoListInfo;
            const index = data.findIndex(el => el.spaceId === this.useId);
            if (index > -1) {
              const arr = data[index];
              const filter = data.filter(el => el.spaceId !== this.useId);
              this.spaceList = [arr, ...filter];
              this.setOverQurationList();
              $state.loaded();
            } else {
              this.limit += 10;
              this.retryGetMySpaceList($state);
            }
          });
        }, 1000);
      });
    },
    deleteIndi(data = []) {
      for (let dataIndex = 0; dataIndex < data.length; dataIndex++) {
        //
        if (data[dataIndex].genre) {
          data[dataIndex].genre = data[dataIndex].genre.filter(genre => genre !== '인디음악');
        }
        if (data[dataIndex].brandImg) {
          data[dataIndex].brandImg = data[dataIndex].brandImg.substr(12);
        }
      }
    },
    setSpaceList(data = [], $state) {
      if (this.$cookies.get('spaceId') != null) {
        //
        const index = data.findIndex(el => el.spaceId === this.useId);
        if (index < 0) {
          this.retryGetMySpaceList($state);
        } else {
          const arr = data[index];
          const filter = data.filter(el => el.spaceId !== this.useId);
          this.spaceList = [arr, ...filter];
          this.setOverQurationList();
          $state.loaded();
        }
      } else {
        this.spaceList = data;
        this.setOverQurationList();
        $state.loaded();
      }
    },
    async infiniteHandler($state) {
      if (this.spaceList.length < 10) {
        $state.complete();
      } else {
        const userId = this.$cookies.get('userId');
        const spaceId = this.$cookies.get('spaceId');
        this.limit += 10;
        getMySpaceList(userId, this.limit, spaceId).then(res => {
          const { result } = res.data;
          const { total } = result;
          const { spaceInfoListInfo } = result;
          this.deleteIndi(spaceInfoListInfo);
          this.setSpaceList(spaceInfoListInfo, $state);
          if (total <= this.limit) {
            $state.complete();
          }
        });
      }
    },
    setOverQurationList() {
      this.$nextTick(() => {
        const list = Array.from(document.querySelectorAll('.quration_list'));
        list.forEach((item, index) => {
          if (item.clientHeight > 76) {
            this.replaceEllipsis(item);
          }
        });
      });
    },
    replaceEllipsis(ele) {
      const child = Array.from(ele.childNodes);

      for (let i = child.length - 1; i >= 0; i--) {
        const childNode = child[i];
        ele.removeChild(childNode);
        const height = ele.clientHeight;
        if (height <= 76) {
          const span = document.createElement('span');
          span.classList.add('ellipsis');
          span.textContent = '...';
          ele.appendChild(span);
          break;
        }
      }
    },
    deleteSpace(space) {
      this.spaceId = space.spaceId;
      this.spaceNm = space.spaceNm;
      this.ShowDelete = true;
    },
    clickSpace(space) {
      this.route = space.route;
      if (space.spacePayAt === 'Y' || space.spacePayAt === 'T') {
        this.spaceId = space.spaceId;
        this.spaceNm = space.spaceNm;
        this.spaceType = space.spaceType;
        this.ShowPlay = true;
      } else if (space.spacePayAt === 'S') {
        this.$store.commit('setTrialStatus', true);
        this.spaceId = space.spaceId;
        this.spaceType = space.spaceType;
        this.ShowAddSpace = true;
      } else {
        this.spaceId = space.spaceId;
        this.spaceType = space.spaceType;
        this.ShowResubscribe = true;
      }
    },
    clickPre() {
      history.back();
    },
    linkInsertSpace() {
      router.push({ name: 'SpaceInsert' }).catch(() => {});
    },
    linkUpdateSpace(space) {
      localStorage.setItem('spaceId', space.spaceId);
      router.push({ name: 'UpdateSpace' });
    },
    linkUpdateFranchise(space) {
      localStorage.setItem('spaceId', space.spaceId);
      router.push({ name: 'Franchise', params: { spaceNm: space.spaceNm, spaceType: space.spaceType } });
    },
    linkAutoPlay(space) {
      localStorage.setItem('spaceId', space.spaceId);
      router.push({ name: 'AutoPlay' });
    },
    linkCarPurpose(space) {
      localStorage.setItem('spaceId', space.spaceId);
      router.push({ name: 'CarPurpose' });
    },
    dateTime(value) {
      return dayjs(value).format('YY.MM.DD');
    },
    set() {
      // $('.setting_show_box').fadeOut();
      $('.back_dim').hide();
      $('.setting_show_box').hide();
    },
    showSetBox(e) {
      $(e.currentTarget)
        .children('.setting_show_box')
        .fadeToggle();
    },
    hideSetBox() {
      $('.setting_show_box p').click(() => {
        $('.setting_show_box').hide();
      });
    },
    showSetBoxMo(e) {
      var setBoxMo = e.currentTarget.parentElement;
      $('.setting_show_box1').css({ bottom: '-100%', transition: 'all 1s' });
      $(setBoxMo)
        .find('.setting_show_box1')
        .css({ bottom: 0, transition: 'all 0.5s' });
      $('.back_dim').show();
    },
    hideSetBoxMo() {
      $('.back_dim').hide();
      $('.setting_show_box1').css({ bottom: '-100%', transition: 'all 1s' });
    },
    hideSetBox2() {
      $(document).mouseup('.main', function() {
        $('.setting_show_box').hide();
      });
    },
    showAside() {
      this.hideSetBoxMo();
      this.asideStatus = true;
    },
    mobileAlarm() {
      this.$router.push({ name: 'Notice0' });
    },
    checkIOS() {
      if (
        navigator.userAgent.indexOf('iPhone') !== -1 ||
        navigator.userAgent.indexOf('iPod') !== -1 ||
        navigator.userAgent.indexOf('iPad') !== -1
      ) {
        this.useIOS = true;
      }
    },
    async setTimeCurationList(spaceId) {
      try {
        const { data } = await selectCurationInfoAll(spaceId);
        const { resultCd, result } = data;
        if (resultCd === '0000') {
          const list = result || [];
          const len = list.length;
          for (let i = 0; i < len; i++) {
            list[i].genre = list[i].genre ? list[i].genre : [];
            list[i].period = list[i].period ? list[i].period : [];
            const target =
              list[i].target.length > 1 ? `${list[i].target[0]}+${list[i].target.length - 1}` : list[i].target[0];
            const targetUnitList =
              list[i].targetUnit.length > 1
                ? `${list[i].targetUnit[0]}+${list[i].targetUnit.length - 1}`
                : list[i].targetUnit[0];
            const domesticRate = `국내${list[i].domesticRate}%`;
            const genre = list[i].genre;
            const period =
              list[i].period.length > 1
                ? `${list[i].period[0]}s+${list[i].period.length - 1}`
                : `${list[i].period[0]}s`;
            const emotion =
              list[i].emotion.length > 1 ? `${list[i].emotion[0]}+${list[i].emotion.length - 1}` : list[i].emotion[0];
            let startAmPm = null;
            let startTimeTitle = null;
            let endtTimeTitle = null;
            let endAmPm = '';
            // 요일
            let day = null;
            if (list[i].dayOfWeek) {
              const daylist = list[i].dayOfWeek
                .split(',')
                .map(item => parseInt(item))
                .sort((a, b) => a - b)
                .map(item => {
                  const week = ['월', '화', '수', '목', '금', '토', '일'];
                  return week[item - 1];
                });
              day = daylist.join(', ');
            }

            // 큐레이션 제목
            let title = null;
            if (list[i].startTime && list[i].endTime) {
              const startTime = list[i].startTime;
              const endTIme = list[i].endTime;
              const startSplit = startTime.split(':');
              const endSplit = endTIme.split(':');
              let startHour = startSplit[0];
              const startMin = startSplit[1];
              let endHour = endSplit[0];
              const endMin = endSplit[1];

              if (parseInt(startHour) >= 12) {
                startAmPm = '오후';
              } else {
                startAmPm = '오전';
              }
              if (parseInt(startHour) - 12 > 0) {
                startHour = parseInt(startHour) - 12;
              }

              startTimeTitle = `${startHour}:${startMin}`;

              if (parseInt(endHour) >= 12) {
                endAmPm = '오후';
              } else {
                endAmPm = '오전';
              }
              if (parseInt(endHour) - 12 > 0) {
                endHour = parseInt(endHour) - 12 > 9 ? parseInt(endHour) - 12 : `0${parseInt(endHour) - 12}`;
              }

              endtTimeTitle = `${endHour}:${endMin}`;
            } else {
              title = '기본(설정시간대 외 큐레이션)';
            }

            let genreTotal = [];
            if (genre) {
              genreTotal = [...genre];
            }

            if (list[i].genreTuneN) {
              genreTotal = [...genreTotal, ...list[i].genreTuneN];
            }

            if (list[i].genreTuneY) {
              genreTotal = [...genreTotal, ...list[i].genreTuneY];
            }

            const curationTimeItem = {
              curationId: list[i].curationId,
              spaceId: list[i].spaceId,
              target,
              targetUnitList,
              domesticRate,
              genre: genreTotal.length > 1 ? `${genreTotal[0]}+${genreTotal.length - 1}` : genreTotal[0],
              period,
              emotion,
              day,
              title,
              startAmPm,
              endAmPm,
              startTimeTitle,
              endtTimeTitle,
              startTimeDate: list[i].startTime,
              endTImeDate: list[i].endTime,
              colorcss: this.getColorCss(i)
            };

            this.curationTimeList.push(curationTimeItem);
          }
        } else {
          this.curationTimeList = [];
        }
      } catch (error) {
        console.error('setTimeCurationList error : ', error);
        this.curationTimeList = [];
      } finally {
        // 나머지 큐레이션은 현재 활성화된 순서
        const creatorCurationId = this.$store.getters['player/getCreatorCurationId'];

        const list = this.curationTimeList.sort(a => {
          if (!a.startTimeDate && !a.endTImeDate) {
            return -1;
          } else {
            return a.curationId === creatorCurationId ? -1 : 0;
          }
        });
        list.forEach((item, index) => {
          item.colorcss = this.getColorCss(index);
        });
        this.curationTimeList = list;
      }
    },
    async onClickCurationitem(space) {
      await this.setTimeCurationList(space.spaceId); // a1
      localStorage.setItem('spaceId', space.spaceId);
      localStorage.setItem('isMyspace_icon', 'isTrue');

      const getFirstBasicArray = this.curationTimeList.find(data => {
        return data.title ? data.title.search(/기본/gi) !== -1 : false;
      });

      const { spaceId, curationId, startTimeDate, endTImeDate } = getFirstBasicArray;

      let curationType = 'default';
      if (startTimeDate && endTImeDate) {
        curationType = 'time';
      }
      this.$router
        .push({ path: `/curationsetting/${spaceId}/${curationId}?curationtype=${curationType}` })
        .catch(() => {});
    },
    getColorCss(index) {
      const csscolorlist = ['second', 'third', 'four', 'five', 'six'];
      if (index === 0) return 'first';
      const mod = index % 5;
      return csscolorlist[mod];
    },
    /**
     * @description 연결해제
     * @author CHOI DAE GEON
     */
    async onDisConnect() {
      await this.disconnect(this.brandId, this.spaceId);
      this.isDisconnect = false;
      this.getList();
    },
    /**
     * @description 이어서등록하기
     * @author CHOI DAE GEON
     */
    onNextInsert() {
      this.nextinsert(this.spaceType, this.spaceId);
    },
    /**
     * @description 재생하시겠습니까 팝업
     * @author CHOI DAE GEON
     */
    onPlay() {
      this.spaceplay(this.spaceType, this.spaceId, this.spaceNm);
    },
    /**
     * @description 재구독
     * @author CHOI DAE GEON
     */
    onReSubscribe() {
      this.resubscribespace(this.spaceType, this.spaceId);
    },
    /**
     * @description 스페이스 삭제
     * @author CHOI DAE GEON
     */
    async onDeleteSpace() {
      const result = await this.deletespace(this.spaceId);
      const { resultMsg, isSuccess } = result;
      if (isSuccess) {
        this.getList();
      } else {
        this.popupModalText = resultMsg;
        this.isPopUpModal = true;
      }
      this.ShowDelete = false;
    },
    /**
     * @description 스페이스 모달 닫기
     * @author CHOI DAE GEON
     */
    onCloseDeleteModal() {
      this.ShowDelete = false;
    },
    /**
     * @description 팝업모달 닫기
     * @author CHOI DAE GEON
     */
    onClosePopUpModal() {
      this.isPopUpModal = false;
    }
  },
  computed: {
    isUseSpace() {
      const video = document.querySelector('#video');
      const isPlay = this.$store.getters.gettersIsPlay;

      if (this.$store.state.isBrandMusic) {
        return this.$store.state.isBrandMusic;
      }
      return isPlay && !video.muted;
    }
  }
});
</script>
<style scoped>
.myspace_item {
  width: 100%;
  height: 100%;
  padding: 24px;
  overflow: hidden;
}
@media all and (max-width: 1300px) {
  .myspace_item {
    padding: 12px;
  }
  .setting_show_box {
    left: -125px;
  }
}
</style>
<style scoped src="@/assets/css/common/deletespacemodal.css"></style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
